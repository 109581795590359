import { isEmpty, isEqual, isUndefined, last } from "lodash-es";

export class Plan {
    // Names
    static PLAN_NAME = {
        BASIC: "Basic",
        COURSE_ONLY: "CourseOnly",
        SELF_STUDY: "SelfStudy",
        ACADEMIC_PREMIUM: "AcademicPremium",
        VPT_ONLY: "VPTOnly",
        PREMIUM: "Premium",
        ONE_PER_WEEK: "1/Week",
        TWO_PER_WEEK: "2/Week",
        THREE_PER_WEEK: "3/Week",
        FOUR_PER_WEEK: "4/Week",
        FIVE_PER_WEEK: "5/Week",
        SIX_PER_WEEK: "6/Week",
        SEVEN_PER_WEEK: "7/Week",
        FOURTEEN_PER_WEEK: "14/Week",
        TWENTY_PER_WEEK: "20/Week",
        THIRTY_PER_WEEK: "30/Week",
        PLATINUM: "Platinum",
        ONE_PER_DAY: "1/Day",
        TWO_PER_DAY: "2/Day",
        STANDARD: "Standard",
        // Native Plans
        PREMIUM_NATIVE: "PremiumNative",
        PREMIUM_NATIVE_LIMITED: "PremiumNativeLimited",
        PREMIUM_NATIVE_LIMITED_PLUS: "PremiumNativeLimitedPlus",
        PREMIUM_LIVE: "PremiumLive",
        ONE_PER_DAY_NATIVE: "1/DayNative",
        ONE_PER_DAY_NATIVE_LIMITED: "1/DayNativeLimited",
        TWO_PER_DAY_NATIVE: "2/DayNative",
        // Native Only Plans
        PREMIUM_NATIVE_ONLY: "PremiumNativeOnly",
        ONE_PER_DAY_NATIVE_ONLY: "1/DayNativeOnly",
        TWO_PER_DAY_NATIVE_ONLY: "2/DayNativeOnly",
        ECJB_SELF_STUDY: "Video Learning",
        ECJB_PREMIUM: "Premium",
        // Platinum Native Only
        NATIVE_2T: "Native 2/T",
        NATIVE_3T: "Native 3/T",
        ECJB_SELF_STUDY_FOUR_LESSONS_YEARLY: "Video Learning 4 Lessons",
        ECJB_SELF_STUDY_SIX_LESSONS_YEARLY: "Video Learning 6 Lessons"
    };

    // Ids
    static PLAN_ID = {
        BASIC: 0,
        // Regular Plans
        SELF_STUDY: 32,
        COURSE_ONLY: 17,
        PREMIUM: 2,
        PLATINUM: 3,
        ONE_PER_DAY: 4,
        ONE_PER_DAY_GRANDFATHERED: 5,
        TWO_PER_DAY: 6,
        TWO_PER_DAY_GRANDFATHERED: 7,
        ONE_PER_DAY_YEARLY_GRANDFATHERED: 8,
        TWO_PER_DAY_YEARLY_GRANDFATHERED: 9,
        // Weekly Plans
        THREE_PER_WEEK: 45,
        THREE_PER_WEEK_ONE_AT_A_TIME: 49,
        FIVE_PER_WEEK: 46,
        // Weekly Plans N at a Time
        ONE_PER_WEEK_N_AT_A_TIME: 63,
        TWO_PER_WEEK_N_AT_A_TIME: 44,
        THREE_PER_WEEK_N_AT_A_TIME: 64,
        FOUR_PER_WEEK_N_AT_A_TIME: 65,
        FIVE_PER_WEEK_N_AT_A_TIME: 46,
        SIX_PER_WEEK_N_AT_A_TIME: 66,
        SEVEN_PER_WEEK_N_AT_A_TIME: 67,
        FOURTEEN_PER_WEEK_N_AT_A_TIME: 79,
        TWENTY_PER_WEEK_N_AT_A_TIME: 80,
        THIRTY_PER_WEEK_N_AT_A_TIME: 81,
        // Native Limited Plan Ids
        PREMIUM_NATIVE_LIMITED: 41,
        ONE_PER_DAY_NATIVE_LIMITED: 42,
        TWO_PER_DAY_NATIVE_LIMITED: 43,
        PREMIUM_NATIVE_LIMITED_PLUS: 48,
        // Native Plan Ids
        PREMIUM_NATIVE: 33,
        ONE_PER_DAY_NATIVE: 34,
        TWO_PER_DAY_NATIVE: 35,
        // Native Only Plan Ids
        PREMIUM_NATIVE_ONLY: 36,
        ONE_PER_DAY_NATIVE_ONLY: 37,
        TWO_PER_DAY_NATIVE_ONLY: 38,
        // Platinum Native Only
        EIGHT_PER_MONTH_NATIVE_TWO_AT_A_TIME: 82,
        TEN_PER_MONTH_NATIVE_THREE_AT_A_TIME: 83,
        TWELVE_PER_MONTH_NATIVE_THREE_AT_A_TIME: 84,
        // Other Plans
        ACADEMIC_PREMIUM: 1,
        VPT_ONLY: 47,
        ECJB_PLATINUM: 68,
        ECJB_VOCAB_ONLY: 69,
        ECJB_SELF_STUDY: 70,
        ECJB_PREMIUM: 71,
        ECJB_PREMIUM_MIXED_TUTOR: 72,
        ECJB_TWO_PER_WEEK_N_AT_A_TIME: 73,
        ECJB_THREE_PER_WEEK_N_AT_A_TIME: 74,
        ECJB_FIVE_PER_WEEK_N_AT_A_TIME: 75,
        ECJB_SEVEN_PER_WEEK_N_AT_A_TIME: 76,
        ECJB_FOURTEEN_PER_WEEK_N_AT_A_TIME: 94,
        ECJB_ONE_PER_DAY: 77,
        ECJB_TWO_PER_DAY: 78,
        ECJB_SELF_STUDY_FOUR_LESSONS_YEARLY: 105,
        ECJB_SELF_STUDY_SIX_LESSONS_YEARLY: 106,
        KSU_PLATINUM: 28,
        NOT_SURE_YET: 99999,
        NOT_INTERESTED: 99999
    };

    static NO_LESSON_PLANS = [
        Plan.PLAN_ID.BASIC,
        Plan.PLAN_ID.SELF_STUDY,
        Plan.PLAN_ID.COURSE_ONLY,
        Plan.PLAN_ID.VPT_ONLY,
        Plan.PLAN_ID.ACADEMIC_PREMIUM
    ];

    static WEEKLY_PLANS: Record<number, number> = {
        // values represent number of lessons per week
        [Plan.PLAN_ID.ONE_PER_WEEK_N_AT_A_TIME]: 1,
        [Plan.PLAN_ID.TWO_PER_WEEK_N_AT_A_TIME]: 2,
        [Plan.PLAN_ID.THREE_PER_WEEK]: 3,
        [Plan.PLAN_ID.THREE_PER_WEEK_ONE_AT_A_TIME]: 3,
        [Plan.PLAN_ID.THREE_PER_WEEK_N_AT_A_TIME]: 3,
        [Plan.PLAN_ID.FOUR_PER_WEEK_N_AT_A_TIME]: 4,
        [Plan.PLAN_ID.FIVE_PER_WEEK]: 5,
        [Plan.PLAN_ID.SIX_PER_WEEK_N_AT_A_TIME]: 6,
        [Plan.PLAN_ID.SEVEN_PER_WEEK_N_AT_A_TIME]: 7,
        [Plan.PLAN_ID.FOURTEEN_PER_WEEK_N_AT_A_TIME]: 14,
        [Plan.PLAN_ID.TWENTY_PER_WEEK_N_AT_A_TIME]: 20,
        [Plan.PLAN_ID.THIRTY_PER_WEEK_N_AT_A_TIME]: 30
    };

    static ECJB_WEEKLY_PLANS: Record<number, number> = {
        // values represent number of lessons per week
        [Plan.PLAN_ID.ECJB_TWO_PER_WEEK_N_AT_A_TIME]: 2,
        [Plan.PLAN_ID.ECJB_THREE_PER_WEEK_N_AT_A_TIME]: 3,
        [Plan.PLAN_ID.ECJB_FIVE_PER_WEEK_N_AT_A_TIME]: 5,
        [Plan.PLAN_ID.ECJB_SEVEN_PER_WEEK_N_AT_A_TIME]: 7,
        [Plan.PLAN_ID.ECJB_FOURTEEN_PER_WEEK_N_AT_A_TIME]: 14
    };

    static WEEKLY_PLANS_BY_NAME: Record<string, number> = {
        // values represent number of lessons per week
        [Plan.PLAN_NAME.ONE_PER_WEEK]: 1,
        [Plan.PLAN_NAME.TWO_PER_WEEK]: 2,
        [Plan.PLAN_NAME.THREE_PER_WEEK]: 3,
        [Plan.PLAN_NAME.FOUR_PER_WEEK]: 4,
        [Plan.PLAN_NAME.FIVE_PER_WEEK]: 5,
        [Plan.PLAN_NAME.SIX_PER_WEEK]: 6,
        [Plan.PLAN_NAME.SEVEN_PER_WEEK]: 7,
        [Plan.PLAN_NAME.FOURTEEN_PER_WEEK]: 14,
        [Plan.PLAN_NAME.TWENTY_PER_WEEK]: 20,
        [Plan.PLAN_NAME.THIRTY_PER_WEEK]: 30
    };

    // Value indicates that user can schedule up to X lessons at a time
    static readonly LESSON_BOOKINGS_AT_A_TIME = {
        [Plan.PLAN_ID.TWO_PER_WEEK_N_AT_A_TIME]: 2,
        [Plan.PLAN_ID.THREE_PER_WEEK]: 3,
        [Plan.PLAN_ID.THREE_PER_WEEK_N_AT_A_TIME]: 3,
        [Plan.PLAN_ID.THREE_PER_WEEK_ONE_AT_A_TIME]: 3,
        [Plan.PLAN_ID.FIVE_PER_WEEK]: 3,
        [Plan.PLAN_ID.FIVE_PER_WEEK_N_AT_A_TIME]: 3,
        [Plan.PLAN_ID.SEVEN_PER_WEEK_N_AT_A_TIME]: 4,
        [Plan.PLAN_ID.FOURTEEN_PER_WEEK_N_AT_A_TIME]: 7,
        [Plan.PLAN_ID.TWENTY_PER_WEEK_N_AT_A_TIME]: 8,
        [Plan.PLAN_ID.THIRTY_PER_WEEK_N_AT_A_TIME]: 8
    };

    static PLATINUM_NATIVE_ONLY_PLANS = [
        Plan.PLAN_ID.EIGHT_PER_MONTH_NATIVE_TWO_AT_A_TIME,
        Plan.PLAN_ID.TEN_PER_MONTH_NATIVE_THREE_AT_A_TIME,
        Plan.PLAN_ID.TWELVE_PER_MONTH_NATIVE_THREE_AT_A_TIME
    ];

    static ACCESSCODE_TEXT_PLAN_IDS = {
        NoText: [
            Plan.PLAN_ID.ACADEMIC_PREMIUM,
            Plan.PLAN_ID.COURSE_ONLY,
            Plan.PLAN_ID.SELF_STUDY,
            Plan.PLAN_ID.VPT_ONLY,
            Plan.PLAN_ID.ECJB_VOCAB_ONLY,
            Plan.PLAN_ID.ECJB_SELF_STUDY
        ],
        PremiumText: [
            Plan.PLAN_ID.PREMIUM,
            Plan.PLAN_ID.PREMIUM_NATIVE,
            Plan.PLAN_ID.PREMIUM_NATIVE_ONLY,
            Plan.PLAN_ID.PREMIUM_NATIVE_LIMITED,
            Plan.PLAN_ID.PREMIUM_NATIVE_LIMITED_PLUS,
            Plan.PLAN_ID.ECJB_PREMIUM,
            Plan.PLAN_ID.ECJB_PREMIUM_MIXED_TUTOR
        ],
        PlatinumText: [
            Plan.PLAN_ID.PLATINUM,
            Plan.PLAN_ID.ECJB_PLATINUM,
            Plan.PLAN_ID.KSU_PLATINUM
        ],
        OnePerDayText: [
            Plan.PLAN_ID.ONE_PER_DAY,
            Plan.PLAN_ID.ONE_PER_DAY_GRANDFATHERED,
            Plan.PLAN_ID.ONE_PER_DAY_YEARLY_GRANDFATHERED,
            Plan.PLAN_ID.ONE_PER_DAY_NATIVE,
            Plan.PLAN_ID.ONE_PER_DAY_NATIVE_ONLY,
            Plan.PLAN_ID.ONE_PER_DAY_NATIVE_LIMITED,
            Plan.PLAN_ID.ECJB_ONE_PER_DAY
        ],
        TwoPerDayText: [
            Plan.PLAN_ID.TWO_PER_DAY,
            Plan.PLAN_ID.TWO_PER_DAY_GRANDFATHERED,
            Plan.PLAN_ID.TWO_PER_DAY_YEARLY_GRANDFATHERED,
            Plan.PLAN_ID.TWO_PER_DAY_NATIVE,
            Plan.PLAN_ID.TWO_PER_DAY_NATIVE_ONLY,
            Plan.PLAN_ID.TWO_PER_DAY_NATIVE_LIMITED,
            Plan.PLAN_ID.ECJB_TWO_PER_DAY
        ],
        NAtATimeText: [
            Plan.PLAN_ID.TWO_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.THREE_PER_WEEK,
            Plan.PLAN_ID.FIVE_PER_WEEK,
            Plan.PLAN_ID.THREE_PER_WEEK_ONE_AT_A_TIME,
            Plan.PLAN_ID.ONE_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.THREE_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.FOUR_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.SIX_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.SEVEN_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.ECJB_TWO_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.ECJB_THREE_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.ECJB_FIVE_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.ECJB_SEVEN_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.FOURTEEN_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.TWENTY_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.THIRTY_PER_WEEK_N_AT_A_TIME,
            Plan.PLAN_ID.EIGHT_PER_MONTH_NATIVE_TWO_AT_A_TIME,
            Plan.PLAN_ID.TEN_PER_MONTH_NATIVE_THREE_AT_A_TIME,
            Plan.PLAN_ID.TWELVE_PER_MONTH_NATIVE_THREE_AT_A_TIME
        ]
    };

    planId?: number;
    planName?: string;
    displayName?: string;
    expired?: boolean;
    active?: boolean;
    subscriptionExpiration?: string;
    paymentDate?: string;
    productId?: number;
    tenderId?: number;

    static isStandard(planName?: string): boolean {
        return isEqual(planName, Plan.PLAN_NAME.STANDARD);
    }

    static isBasic(planName: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.BASIC) || (!planId && isEmpty(planName)) || (planId === Plan.PLAN_ID.BASIC);
    }

    static isCourseOnly(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.COURSE_ONLY) || (planId === Plan.PLAN_ID.COURSE_ONLY);
    }

    static isSelfStudy(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.SELF_STUDY) || (planId === Plan.PLAN_ID.SELF_STUDY);
    }

    static isEcjbSelfStudy(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ECJB_SELF_STUDY) || (planId === Plan.PLAN_ID.ECJB_SELF_STUDY);
    }

    static isSelfStudyFourLessonsYearly(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ECJB_SELF_STUDY_FOUR_LESSONS_YEARLY) ||
            (planId === Plan.PLAN_ID.ECJB_SELF_STUDY_FOUR_LESSONS_YEARLY);
    }

    static isSelfStudySixLessonsYearly(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ECJB_SELF_STUDY_SIX_LESSONS_YEARLY) ||
            (planId === Plan.PLAN_ID.ECJB_SELF_STUDY_SIX_LESSONS_YEARLY);
    }

    static isAcademicPremium(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ACADEMIC_PREMIUM) || (planId === Plan.PLAN_ID.ACADEMIC_PREMIUM);
    }

    static isVptOnly(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.VPT_ONLY) || (planId === Plan.PLAN_ID.VPT_ONLY);
    }

    static isPremium(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.PREMIUM) || (planId === Plan.PLAN_ID.PREMIUM);
    }

    static isPremiumNative(planName?: string, planId?: number): boolean {
        const premiumLiveExclamation = `${Plan.PLAN_NAME.PREMIUM_LIVE}!`;
        return isEqual(planName, Plan.PLAN_NAME.PREMIUM_NATIVE)
            || isEqual(planName, Plan.PLAN_NAME.PREMIUM_LIVE)
            || (planName == premiumLiveExclamation)
            || (planId === Plan.PLAN_ID.PREMIUM_NATIVE);
    }

    static isPremiumNativeOnly(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.PREMIUM_NATIVE_ONLY)
            || (planId === Plan.PLAN_ID.PREMIUM_NATIVE_ONLY);
    }

    static isPremiumNativeLimited(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.PREMIUM_NATIVE_LIMITED) || (planId === Plan.PLAN_ID.PREMIUM_NATIVE_LIMITED);
    }

    static isPremiumNativeLimitedPlus(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.PREMIUM_NATIVE_LIMITED_PLUS) || (planId === Plan.PLAN_ID.PREMIUM_NATIVE_LIMITED_PLUS);
    }

    static isEcjbPremium(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ECJB_PREMIUM) || (planId === Plan.PLAN_ID.ECJB_PREMIUM);
    }

    static isPlatinum(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.PLATINUM) || (planId === Plan.PLAN_ID.PLATINUM);
    }

    static isPlatinumNativeOnly(planId?: number): boolean {
        return Plan.PLATINUM_NATIVE_ONLY_PLANS.includes(planId);
    }

    static isOnePerDay(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ONE_PER_DAY) || (planId === Plan.PLAN_ID.ONE_PER_DAY);
    }

    static isOnePerDayGrandfathered(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ONE_PER_DAY) || (planId === Plan.PLAN_ID.ONE_PER_DAY_GRANDFATHERED);
    }

    static isOnePerDayNative(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ONE_PER_DAY_NATIVE) || (planId === Plan.PLAN_ID.ONE_PER_DAY_NATIVE);
    }

    static isOnePerDayNativeOnly(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ONE_PER_DAY_NATIVE_ONLY) || (planId === Plan.PLAN_ID.ONE_PER_DAY_NATIVE_ONLY);
    }

    static isOnePerDayNativeLimited(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.ONE_PER_DAY_NATIVE_LIMITED) || (planId === Plan.PLAN_ID.ONE_PER_DAY_NATIVE_LIMITED);
    }

    static isTwoPerDay(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.TWO_PER_DAY) || (planId === Plan.PLAN_ID.TWO_PER_DAY);
    }

    static isTwoPerDayGrandfathered(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.TWO_PER_DAY) || (planId === Plan.PLAN_ID.TWO_PER_DAY_GRANDFATHERED);
    }

    static isTwoPerDayNative(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.TWO_PER_DAY_NATIVE) || (planId === Plan.PLAN_ID.TWO_PER_DAY_NATIVE);
    }

    static isTwoPerDayNativeOnly(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.TWO_PER_DAY_NATIVE_ONLY) || (planId === Plan.PLAN_ID.TWO_PER_DAY_NATIVE_ONLY);
    }

    static isTwoPerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.TWO_PER_WEEK) || (planId === Plan.PLAN_ID.TWO_PER_WEEK_N_AT_A_TIME) || (planId === Plan.PLAN_ID.ECJB_TWO_PER_WEEK_N_AT_A_TIME);
    }

    static isOnePerWeek(planName?: string, planId?: number): boolean {
        // @TODO: PlanId for one per week should be put here.
        return isEqual(planName, Plan.PLAN_NAME.ONE_PER_WEEK);
    }

    static isThreePerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.THREE_PER_WEEK)
            || (planId === Plan.PLAN_ID.THREE_PER_WEEK)
            || (planId === Plan.PLAN_ID.THREE_PER_WEEK_N_AT_A_TIME)
            || (planId === Plan.PLAN_ID.THREE_PER_WEEK_ONE_AT_A_TIME)
            || (planId === Plan.PLAN_ID.ECJB_THREE_PER_WEEK_N_AT_A_TIME);
    }

    static isThreePerWeekOneAtATime(planId: number): boolean {
        return (planId === Plan.PLAN_ID.THREE_PER_WEEK_ONE_AT_A_TIME);
    }

    static isFourPerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.FOUR_PER_WEEK)
            || (planId === Plan.PLAN_ID.FOUR_PER_WEEK_N_AT_A_TIME);
    }

    static isFivePerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.FIVE_PER_WEEK)
            || (planId === Plan.PLAN_ID.FIVE_PER_WEEK)
            || (planId === Plan.PLAN_ID.FIVE_PER_WEEK_N_AT_A_TIME)
            || (planId === Plan.PLAN_ID.ECJB_FIVE_PER_WEEK_N_AT_A_TIME);
    }

    static isSixPerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.SIX_PER_WEEK)
            || (planId === Plan.PLAN_ID.SIX_PER_WEEK_N_AT_A_TIME);
    }

    static isSevenPerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.SEVEN_PER_WEEK)
            || (planId === Plan.PLAN_ID.SEVEN_PER_WEEK_N_AT_A_TIME)
            || (planId === Plan.PLAN_ID.ECJB_SEVEN_PER_WEEK_N_AT_A_TIME);
    }

    static isFourteenPerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.FOURTEEN_PER_WEEK)
            || (planId === Plan.PLAN_ID.FOURTEEN_PER_WEEK_N_AT_A_TIME)
            || (planId === Plan.PLAN_ID.ECJB_FOURTEEN_PER_WEEK_N_AT_A_TIME);
    }

    static isTwentyPerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.TWENTY_PER_WEEK)
            || (planId === Plan.PLAN_ID.TWENTY_PER_WEEK_N_AT_A_TIME);
    }

    static isThirtyPerWeek(planName?: string, planId?: number): boolean {
        return isEqual(planName, Plan.PLAN_NAME.THIRTY_PER_WEEK)
            || (planId === Plan.PLAN_ID.THIRTY_PER_WEEK_N_AT_A_TIME);
    }

    static isNativePlan(planName?: string, planId?: number): boolean {
        return Plan.isPremiumNative(planName, planId)
            || Plan.isPremiumNativeLimited(planName, planId)
            || Plan.isPremiumNativeLimitedPlus(planName, planId)
            || Plan.isOnePerDayNative(planName, planId)
            || Plan.isOnePerDayNativeOnly(planName, planId)
            || Plan.isTwoPerDayNative(planName, planId)
            || Plan.isTwoPerDayNativeOnly(planName, planId)
            || Plan.isThreePerWeek(planName, planId);
    }

    static isAnyOnePerDayPlan(planName?: string, planId?: number): boolean {
        return Plan.isOnePerDay(planName, planId)
            || Plan.isOnePerDayNative(planName, planId)
            || Plan.isOnePerDayNativeOnly(planName, planId)
            || Plan.isOnePerDayNativeLimited(planName, planId);
    }

    static isAnyTwoPerDayPlan(planName?: string, planId?: number): boolean {
        return Plan.isTwoPerDay(planName, planId)
            || Plan.isTwoPerDayNative(planName, planId)
            || Plan.isTwoPerDayNativeOnly(planName, planId);
    }

    static isAnyPremiumPlan(planName?: string, planId?: number): boolean {
        return Plan.isPremium(planName, planId)
            || Plan.isPremiumNativeLimited(planName, planId)
            || Plan.isPremiumNativeLimitedPlus(planName, planId)
            || Plan.isPremiumNative(planName, planId)
            || Plan.isPremiumNativeOnly(planName, planId)
            || Plan.isEcjbPremium(planName, planId);
    }

    static isAnyPerWeekPlan(planName?: string, planId?: number): boolean {
        if (!isEmpty(planName) && Object.keys(Plan.WEEKLY_PLANS_BY_NAME).includes(planName)) {
            return true;
        }
        if (!isUndefined(planId)) {
            return Object.keys({ ...Plan.WEEKLY_PLANS, ...Plan.ECJB_WEEKLY_PLANS}).map(key => parseInt(key)).includes(planId);
        }
        return false;
    }

    static getLessonsAllowedPerWeek(planId: number): number {
        const NOT_APPLICABLE = -1;
        // this function is only for per week plans
        return Plan.WEEKLY_PLANS[planId] || Plan.ECJB_WEEKLY_PLANS[planId] || NOT_APPLICABLE;
    }

    static isHighestPlanByPaymentPlans(planId: number, paymentPlans: string): boolean {
        const highestPlanId = last(paymentPlans?.split(","));
        return isEqual(planId, parseInt(highestPlanId));
    }

    static isLangrichPlan(planId: number): boolean {
        return planId == Plan.PLAN_ID.ONE_PER_DAY_GRANDFATHERED
            || planId == Plan.PLAN_ID.TWO_PER_DAY_GRANDFATHERED
            || planId == Plan.PLAN_ID.ONE_PER_DAY_YEARLY_GRANDFATHERED
            || planId == Plan.PLAN_ID.TWO_PER_DAY_YEARLY_GRANDFATHERED;
    }

    static isGoLiveEligiblePlan(planId: number): boolean {
        return !Plan.NO_LESSON_PLANS.includes(planId);
    }
}
