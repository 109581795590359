import { Routes } from "@angular/router";
import { ROUTE_BROWSE, ROUTE_VI_LANDING_CAMPAIGN, ROUTE_VIDEOS } from "./routes";
import { partnerParanaAuthGuard } from "../modules/partners/partner-parana/partner-parana-auth-guard";

export const ROUTE_PARTNER_ROOT: string = "partner";
export const ROUTE_PARTNER_UNICESUMAR: string = "unicesumar";
export const ROUTE_PARTNER_UNICESUMAR_POSTGRAD: string = "unicesumar-postgrad";
export const ROUTE_PARTNER_UNIVALI: string = "univali";
export const ROUTE_PARTNER_UOL: string = "uolsapiencia";
export const ROUTE_PARTNER_TURKISH_AIRLINES: string = "turkishairlines";
export const ROUTE_PARTNER_CLASSI: string = "classi";
export const ROUTE_PARTNER_ROCKFELLER: string = "rockfeller";
export const ROUTE_PARTNER_CASATHOMASJEFERSON: string = "casathomasjeferson";
export const ROUTE_PARTNER_SHADOW: string = "shadow";
export const ROUTE_PARTNER_UPF: string = "upf";
export const ROUTE_PARTNER_CATHO: string = "catho";
export const ROUTE_PARTNER_LANGRICH: string = "langrich";
export const ROUTE_PARTNER_SOL: string = "sol";
export const ROUTE_PARTNER_BOMJESUS: string = "bomjesus";
export const ROUTE_PARTNER_TELELANGUE: string = "telelangue";
export const ROUTE_PARTNER_AFYA: string = "afya";
export const ROUTE_PARTNER_BERLITZ: string = "berlitz";
export const ROUTE_PARTNER_FGW: string = "fgw";
export const ROUTE_PARTNER_VIDEOENGLISH: string = "videoenglish";
export const ROUTE_PARTNER_BRAZCUBAS: string = "brazcubas";
export const ROUTE_PARTNER_BERNOULLI: string = "bernoulli";
export const ROUTE_PARTNER_DESCOMPLICA: string = "descomplica";
export const ROUTE_PARTNER_TAI: string = "tai";
export const ROUTE_PARTNER_ESTACIO: string = "estacio";
export const ROUTE_PARTNER_KSU: string = "ksu";
export const ROUTE_PARTNER_VIETTEL: string = "viettel";
export const ROUTE_PARTNER_SEIBIDO: string = "seibido";
export const ROUTE_PARTNER_REVELO: string = "revelo";
export const ROUTE_PARTNER_NECTAR: string = "nectar";
export const ROUTE_PARTNER_SATSI: string = "satsi";
export const ROUTE_PARTNER_BEFREE: string = "befree";
export const ROUTE_PARTNER_GCT: string = "gct";
export const ROUTE_PARTNER_GLOBED: string = "globed";
export const ROUTE_PARTNER_GLOBED2: string = "globed2";
export const ROUTE_PARTNER_SEEDPR: string = "seedpr";
export const ROUTE_PARTNER_VNPT_LOGIN: string = "vnptlogin";
export const ROUTE_PARTNER_IGROUP_VIETNAM: string = "igroup-vietnam";
export const ROUTE_PARTNER_NORTHSUN: string = "northsun";
export const ROUTE_PARTNER_EDITORA_FUTURA: string = "editorafutura";
export const ROUTE_PARTNER_VINUNI: string = "ndkhnta";
export const ROUTE_PARTNER_TOPCV: string = "topcv";
export const ROUTE_PARTNER_VNPT: string = "vnpt";
export const ROUTE_PARTNER_SEMED_SALVADOR: string = "futura-salvador";
export const ROUTE_PARTNER_PARANA: string = "parana";

export const ROUTE_COURSES: string = "courses";
export const ROUTE_AUTHENTICATE: string = "authenticate";
export const ROUTE_ACCESSCODE: string = "accesscode";
export const ROUTE_WELCOME: string = "welcome";

export const ROUTE_PARTNER_CLASSI_LOGIN_URL: string = "/login/classi";

export const ROUTES_PARTNERS: Routes = [
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_UNICESUMAR}`,
        loadChildren: () => import("../views/partner-views/partner-unicesumar-view/partner-unicesumar-view.module").then(m => m.PartnerUnicesumarViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_UNIVALI}`,
        loadChildren: () => import("../views/partner-views/partner-univali-view/partner-univali-view.module").then(m => m.PartnerUnivaliViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_UOL}`,
        loadChildren: () => import("../views/partner-views/partner-uol-view/partner-uol-view.module").then(m => m.PartnerUolViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_UNICESUMAR_POSTGRAD}`,
        loadChildren: () => import("../views/partner-views/partner-unicesumar-view/partner-unicesumar-view.module").then(m => m.PartnerUnicesumarViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_TURKISH_AIRLINES}`,
        loadChildren: () => import("../views/partner-views/partner-turkish-airlines-view/partner-turkish-airlines-view.module").then(m => m.PartnerTurkishAirlinesViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_ROCKFELLER}`,
        loadChildren: () => import("../views/partner-views/partner-rockfeller-view/partner-rockfeller-view.module").then(m => m.PartnerRockfellerViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_UPF}`,
        loadChildren: () => import("../views/partner-views/partner-upf-view/partner-upf-view.module").then(m => m.PartnerUpfViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_SHADOW}`,
        loadChildren: () => import("../views/partner-views/partner-shadow-view/partner-shadow-view.module").then(m => m.PartnerShadowViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_CATHO}`,
        loadChildren: () => import("../views/partner-views/partner-catho-view/partner-catho-view.module").then(m => m.PartnerCathoViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_LANGRICH}`,
        loadChildren: () => import("../views/partner-views/partner-langrich-view/partner-langrich-view.module").then(m => m.PartnerLangrichViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_SOL}`,
        loadChildren: () => import("../views/partner-views/partner-sol-view/partner-sol-view.module").then(m => m.PartnerSolViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_BOMJESUS}`,
        loadChildren: () => import("../views/partner-views/partner-bomjesus-view/partner-bomjesus-view.module").then(m => m.PartnerBomjesusViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_TELELANGUE}`,
        loadChildren: () => import("../views/partner-views/partner-telelangue-view/partner-telelangue-view.module").then(m => m.PartnerTelelangueViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_AFYA}`,
        loadChildren: () => import("../views/partner-views/partner-afya-view/partner-afya-view.module").then(m => m.PartnerAfyaViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_BERLITZ}`,
        loadChildren: () => import("../views/partner-views/partner-berlitz-view/partner-berlitz-view.module").then(m => m.PartnerBerlitzViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_FGW}`,
        loadChildren: () => import("../views/partner-views/partner-fgw-view/partner-fgw-view.module").then(m => m.PartnerFgwViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VIDEOENGLISH}`,
        loadChildren: () => import("../views/partner-views/partner-videoenglish-view/partner-videoenglish-view.module").then(m => m.PartnerVideoEnglishViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_BRAZCUBAS}`,
        loadChildren: () => import("../views/partner-views/partner-brazcubas-view/partner-brazcubas-view.module").then(m => m.PartnerBrazCubasViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_BERNOULLI}`,
        loadChildren: () => import("../views/partner-views/partner-bernoulli-view/partner-bernoulli-view.module").then(m => m.PartnerBernoulliViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_DESCOMPLICA}`,
        loadChildren: () => import("../views/partner-views/partner-descomplica-view/partner-descomplica-view.module").then(m => m.PartnerDescomplicaViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_TAI}`,
        loadChildren: () => import("../views/partner-views/partner-tai-view/partner-tai-view.module").then(m => m.PartnerTaiViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_CLASSI}`,
        loadChildren: () => import("../views/partner-views/partner-classi-view/partner-classi-view.module").then(m => m.PartnerClassiViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_ESTACIO}`,
        loadChildren: () => import("../views/partner-views/partner-estacio-view/partner-estacio-view.module").then(m => m.PartnerEstacioViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_KSU}`,
        loadChildren: () => import("../views/partner-views/partner-ksu-view/partner-ksu-view.module").then(m => m.PartnerKsuViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VIETTEL}`,
        loadChildren: () => import("../views/partner-views/partner-viettel-view/partner-viettel-view.module").then(m => m.PartnerViettelViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_SEIBIDO}`,
        loadChildren: () => import("../views/partner-views/partner-seibido-view/partner-seibido-view.module").then(m => m.PartnerSeibidoViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_REVELO}`,
        loadChildren: () => import("../views/partner-views/partner-revelo-view/partner-revelo-view.module").then(m => m.PartnerReveloViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_NECTAR}`,
        loadChildren: () => import("../views/partner-views/partner-nectar-view/partner-nectar-view.module").then(m => m.PartnerNectarViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_SATSI}`,
        loadChildren: () => import("../views/partner-views/partner-satsi-view/partner-satsi-view.module").then(m => m.PartnerSatsiViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_BEFREE}`,
        loadChildren: () => import("../views/partner-views/partner-befree-view/partner-befree-view.module").then(m => m.PartnerBefreeViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_GCT}`,
        loadChildren: () => import("../views/partner-views/partner-gct-view/partner-gct-view.module").then(m => m.PartnerGctViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_GLOBED}`,
        loadChildren: () => import("../views/partner-views/partner-globed-view/partner-globed-view.module").then(m => m.PartnerGlobedViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_GLOBED2}`,
        loadChildren: () => import("../views/partner-views/partner-globed-view/partner-globed-view.module").then(m => m.PartnerGlobedViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_SEEDPR}`,
        loadChildren: () => import("../views/partner-views/partner-seedpr-view/partner-seedpr-view.module").then(m => m.PartnerSeedprViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VNPT_LOGIN}`,
        loadChildren: () => import("../views/partner-views/partner-vnpt-login-view/partner-vnpt-login-view.module").then(m => m.PartnerVnptLoginViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_IGROUP_VIETNAM}`,
        loadChildren: () => import("../views/partner-views/partner-igroup-vietnam-view/partner-igroup-vietnam-view.module").then(m => m.PartnerIgroupVietnamViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_NORTHSUN}`,
        loadChildren: () => import("../modules/partners/partner-northsun/partner-northsun.module").then(m => m.PartnerNorthsunModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_EDITORA_FUTURA}`,
        loadChildren: () => import("../modules/partners/partner-editora-futura/partner-editora-futura.module").then(m => m.PartnerEditoraFuturaModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_CASATHOMASJEFERSON}`,
        redirectTo: `/${ROUTE_BROWSE}/${ROUTE_VIDEOS}`
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VINUNI}`,
        loadChildren: () => import("../views/partner-views/partner-vinuni-view/partner-vinuni-view.module").then(m => m.PartnerVinuniViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_TOPCV}`,
        loadChildren: () => import("../modules/partners/partner-topcv/partner-topcv.module").then(m => m.PartnerTopcvModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_VNPT}`,
        loadChildren: () => import("../modules/partners/partner-vnpt/partner-vnpt.module").then(m => m.PartnerVnptModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_VI_LANDING_CAMPAIGN}`,
        redirectTo: `/${ROUTE_BROWSE}/${ROUTE_VIDEOS}`
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_SEMED_SALVADOR}`,
        loadChildren: () => import("../views/partner-views/partner-semed-salvador-view/partner-semed-salvador-view.module").then(m => m.PartnerSemedSalvadorViewModule)
    },
    {
        path: `${ROUTE_PARTNER_ROOT}/${ROUTE_PARTNER_PARANA}`,
        loadComponent: () => import("../modules/partners/partner-parana/partner-parana.component").then(c => c.PartnerParanaComponent),
        canActivate: [partnerParanaAuthGuard]
    }
];
