import { Routes } from "@angular/router";
import { AuthGuard } from "../../common-app/auth/auth.guard";
import { CanActivateChildVideoView } from "../views/video-view/can-activate-child-video-view";
import { LandingPageRedirectorGuard } from "./landing-page-redirector.guard";
import {
    ROUTE_ABOUT_US,
    ROUTE_ACADEMIC,
    ROUTE_ACADEMIC_ENGLISH_LANDING,
    ROUTE_AGORA_MEETING,
    ROUTE_AUTHENTICATION,
    ROUTE_BROWSE,
    ROUTE_BUSINESS_ENGLISH_LANDING,
    ROUTE_CHAT_ROLEPLAY,
    ROUTE_CHAT_WIZARD,
    ROUTE_COMPANY_INFORMATION,
    ROUTE_CONVERSATIONS,
    ROUTE_COOKIE_POLICY,
    ROUTE_CORPORATE,
    ROUTE_COURSE,
    ROUTE_DAILY_ENGLISH_LANDING,
    ROUTE_EC_EXPERIENCE,
    ROUTE_ENGLISH_FOR_KIDS_LANDING,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_HOME,
    ROUTE_INDIVIDUAL_SUPPORT,
    ROUTE_INVITATION,
    ROUTE_INVITE,
    ROUTE_JAL_LANDING,
    ROUTE_KIDS,
    ROUTE_KIRIHARA_TEACHER_TOOLS,
    ROUTE_LANDING_AI_SPEAKING_JAPAN,
    ROUTE_LANDING_GAMIFICATION_JAPAN,
    ROUTE_LEGAL,
    ROUTE_LESSON_PLAN,
    ROUTE_LESSON_REPORT,
    ROUTE_LESSONS,
    ROUTE_MARKETING,
    ROUTE_MEDIATEST,
    ROUTE_MEETING_ROOM,
    ROUTE_MOBILE_LANDING_JAPAN,
    ROUTE_MOBILE_LANDING_KOREA,
    ROUTE_MOBILE_LANDING_KOREA_CHRISTMAS,
    ROUTE_MY_CLASS,
    ROUTE_MY_ENGLISH,
    ROUTE_NOT_FOUND,
    ROUTE_PAYMENT,
    ROUTE_PAYMENT_CHECKOUT,
    ROUTE_PAYMENT_CUSTOM,
    ROUTE_PAYMENT_SELECT_METHOD,
    ROUTE_PRIVACY_POLICY,
    ROUTE_PROFILE_SETTINGS,
    ROUTE_RANKINGS,
    ROUTE_REDEEM_ACCESSCODE,
    ROUTE_RESET_PASSWORD,
    ROUTE_RTC_CLASSROOM,
    ROUTE_SEARCH,
    ROUTE_SPEAKING,
    ROUTE_STUDENT,
    ROUTE_STUDENTS,
    ROUTE_TEACHER_TOOLS,
    ROUTE_TERMS_OF_USE,
    ROUTE_TESTCENTRAL,
    ROUTE_TRANSLATOR,
    ROUTE_UNSUBSCRIBE,
    ROUTE_USER_ELUCIDATION,
    ROUTE_VI_TELCO_SUBSCRIPTIONS,
    ROUTE_VI_LANDING,
    ROUTE_VI_LANDING_CAMPAIGN,
    ROUTE_VIDEO,
    ROUTE_VLT_TEACHER_TOOLS,
    ROUTE_VOCABULARY,
    ROUTE_BUSINESS_SKILLS_COURSES,
    ROUTE_COOORI_UNIVERSITY,
    ROUTE_VERSANT_TESTS, ROUTE_LTI_ACCOUNT_LINKING,
    ROUTE_TR_LANDING
} from "./routes";
import { WebviewGuard } from "../../common-app/auth/webview.guard";

export const ROUTES_CORE: Routes = [
    {
        path: "",
        loadChildren: () => import("../views/home/home.module").then(m => m.HomeModule),
        canActivate: [LandingPageRedirectorGuard]
    },
    {
        path: ROUTE_AUTHENTICATION,
        loadChildren: () => import("../views/authentication-view/authentication-view.module").then(m => m.AuthenticationViewModule)
    },
    {
        path: ROUTE_BROWSE,
        loadChildren: () => import("../views/browse-view/browse-view.module").then(m => m.BrowseViewModule)
    },
    {
        path: ROUTE_HOME,
        loadChildren: () => import("../views/home/home.module").then(m => m.HomeModule)
    },
    {
        path: ROUTE_VOCABULARY,
        loadChildren: () => import("../views/vocabulary-view/vocabulary-view.module").then(m => m.VocabularyViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_SPEAKING,
        loadChildren: () => import("../views/pronunciation-view/pronunciation-view.module").then(m => m.PronunciationViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_LESSONS,
        loadChildren: () => import("../views/lessons-view/lessons-view.module").then(m => m.LessonsViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_VIDEO,
        loadChildren: () => import("../views/video-view/video-view.module").then(m => m.VideoViewModule),
        canActivateChild: [CanActivateChildVideoView],
        data: { preload: true, loadAfterSeconds: 5 }
    },
    {
        path: ROUTE_COURSE,
        loadChildren: () => import("../views/course-view/course-view.module").then(m => m.CourseViewModule),
        data: { preload: true, loadAfterSeconds: 5 }
    },
    {
        path: ROUTE_FORGOT_PASSWORD,
        loadChildren: () => import("../views/forgot-password-view/forgot-password-view.module").then(m => m.ForgotPasswordViewModule)
    },
    {
        path: ROUTE_RESET_PASSWORD,
        loadChildren: () => import("../views/reset-password-view/reset-password-view.module").then(m => m.ResetPasswordViewModule)
    },
    {
        path: ROUTE_MY_ENGLISH,
        loadChildren: () => import("../views/my-english-view/my-english-view.module").then(m => m.MyEnglishViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_MY_CLASS,
        loadChildren: () => import("../views/my-class-view/my-class-view.module").then(m => m.MyClassViewModule)
    },
    {
        path: ROUTE_STUDENT,
        loadChildren: () => import("../views/student-enroll-view/student-enroll-view.module").then(m => m.StudentEnrollViewModule)
    },
    {
        path: ROUTE_STUDENTS,
        loadChildren: () => import("../views/student-enroll-view/student-enroll-view.module").then(m => m.StudentEnrollViewModule)
    },
    {
        path: ROUTE_SEARCH,
        loadChildren: () => import("../views/search-view/search-view.module").then(m => m.SearchViewModule)
    },
    {
        path: ROUTE_PROFILE_SETTINGS,
        loadChildren: () => import("../views/profile-settings-view/profile-settings-view.module").then(m => m.ProfileSettingsViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_RANKINGS,
        loadChildren: () => import("../views/rankings-view/rankings-view.module").then(m => m.RankingsViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_LESSON_PLAN,
        loadChildren: () => import("../views/lesson-plan-view/lesson-plan-view.module").then(m => m.LessonPlanViewModule)
    },
    {
        path: ROUTE_LESSON_REPORT,
        loadChildren: () => import("../views/lesson-report-view/lesson-report-view.module").then(m => m.LessonReportViewModule)
    },
    {
        path: ROUTE_PAYMENT,
        loadChildren: () => import("../views/payment-view/payment-view.module").then(m => m.PaymentViewModule)
    },
    {
        path: ROUTE_PAYMENT_CUSTOM,
        loadChildren: () => import("../views/payment-custom-view/payment-custom-view.module").then(m => m.PaymentCustomViewModule)
    },
    {
        path: ROUTE_PAYMENT_CHECKOUT,
        loadChildren: () => import("../views/payment-checkout-view/payment-checkout-view.module").then(m => m.PaymentCheckoutViewModule)
    },
    {
        path: ROUTE_PAYMENT_SELECT_METHOD,
        loadChildren: () => import("../views/payment-select-method-view/payment-select-method-view.module").then(m => m.PaymentSelectMethodViewModule)
    },
    {
        path: ROUTE_ACADEMIC,
        loadChildren: () => import("../views/academic-view/academic-view.module").then(m => m.AcademicViewModule)
    },
    {
        path: ROUTE_TEACHER_TOOLS,
        loadChildren: () => import("../views/teacher-tools-view/teacher-tools-view.module").then(m => m.TeacherToolsViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_KIRIHARA_TEACHER_TOOLS,
        loadChildren: () => import("../views/teacher-tools-view/teacher-tools-view.module").then(m => m.TeacherToolsViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_VLT_TEACHER_TOOLS,
        loadChildren: () => import("../views/teacher-tools-view/teacher-tools-view.module").then(m => m.TeacherToolsViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_COMPANY_INFORMATION,
        loadChildren: () => import("../views/company-information-view/company-information-view.module").then(m => m.CompanyInformationViewModule)
    },
    {
        path: ROUTE_INDIVIDUAL_SUPPORT,
        loadChildren: () => import("../views/individual-support-view/individual-support-view.module").then(m => m.IndividualSupportViewModule)
    },
    {
        path: ROUTE_ABOUT_US,
        loadChildren: () => import("../views/about-us-view/about-us-view.module").then(m => m.AboutUsViewModule)
    },
    {
        path: ROUTE_REDEEM_ACCESSCODE,
        loadChildren: () => import("../views/redeem-accesscode-view/redeem-accesscode-view.module").then(m => m.RedeemAccesscodeViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_USER_ELUCIDATION,
        loadChildren: () => import("../views/user-elucidation-view/user-elucidation-view.module").then(m => m.UserElucidationViewModule)
    },
    {
        path: ROUTE_TERMS_OF_USE,
        loadChildren: () => import("../views/terms-of-use-view/terms-of-use-view.module").then(m => m.TermsOfUseViewModule)
    },
    {
        path: ROUTE_COOKIE_POLICY,
        loadChildren: () => import("../views/cookies-policy-view/cookies-policy-view.module").then(m => m.CookiesPolicyViewModule)
    },
    {
        path: ROUTE_PRIVACY_POLICY,
        loadChildren: () => import("../views/privacy-policy-view/privacy-policy-view.module").then(m => m.PrivacyPolicyViewModule)
    },
    {
        path: ROUTE_EC_EXPERIENCE,
        loadChildren: () => import("../views/ec-experience-view/ec-experience-view.module").then(m => m.EcExperienceViewModule)
    },
    {
        path: ROUTE_MOBILE_LANDING_KOREA,
        loadChildren: () => import("../views/mobile-landing-ko-view/mobile-landing-ko-view.module").then(m => m.MobileLandingKoViewModule)
    },
    {
        path: ROUTE_MOBILE_LANDING_JAPAN,
        redirectTo: ROUTE_BROWSE
    },
    {
        path: ROUTE_LANDING_AI_SPEAKING_JAPAN,
        loadChildren: () => import("../views/mobile-landing-jp-view/landing-ai-speaking-view/landing-ai-speaking-view.module").then(m => m.LandingAiSpeakingViewModule)
    },
    {
        path: ROUTE_LANDING_GAMIFICATION_JAPAN,
        loadChildren: () => import("../views/mobile-landing-jp-view/landing-gamification-view/landing-gamification-view.module").then(m => m.LandingGamificationViewModule)
    },
    {
        path: ROUTE_INVITE,
        loadChildren: () => import("../views/refer-friend-view/refer-friend-view.module").then(m => m.ReferFriendViewModule)
    },
    {
        path: ROUTE_INVITATION,
        loadChildren: () => import("../views/refer-friend-view/refer-friend-view.module").then(m => m.ReferFriendViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: `${ROUTE_RTC_CLASSROOM}/:sessionId`,
        loadChildren: () => import("../../rtc-classroom/rtc-classroom.module").then(m => m.RtcClassroomModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_MOBILE_LANDING_KOREA_CHRISTMAS,
        loadChildren: () => import("../views/mobile-landing-ko-christmas-view/mobile-landing-ko-christmas-view.module").then(m => m.MobileLandingKoChristmasViewModule)
    },
    {
        path: ROUTE_JAL_LANDING,
        loadChildren: () => import("../views/jal-landing-page-view/jal-landing-page-view.module").then(m => m.JalLandingPageViewModule)
    },
    {
        path: ROUTE_MEETING_ROOM,
        loadChildren: () => import("../views/video-meeting-view/video-meeting-view.module").then(m => m.VideoMeetingViewModule)
    },
    {
        path: ROUTE_CHAT_ROLEPLAY,
        loadChildren: () => import("../modules/chat-roleplay-app/chat-roleplay-app.routing.module").then(m => m.ChatRoleplayAppRoutingModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_CHAT_WIZARD,
        loadChildren: () => import("../views/twilio-chat-wizard/twilio-chat-wizard-view.module").then(m => m.TwilioChatWizardViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_TRANSLATOR,
        loadChildren: () => import("../../common-app/translator-app/translator-app.routing.module").then(m => m.TranslatorAppRoutingModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_NOT_FOUND,
        loadChildren: () => import("../views/not-found-view/not-found-view.module").then(m => m.NotFoundViewModule)
    },
    {
        path: ROUTE_UNSUBSCRIBE,
        loadChildren: () => import("../views/mail-unsubscribe-view/mail-unsubscribe-view.module").then(m => m.MailUnsubscribeViewModule)
    },
    {
        path: ROUTE_LEGAL,
        loadChildren: () => import("../views/legal-view/legal-view.module").then(m => m.LegalViewModule)
    },
    {
        path: ROUTE_MEDIATEST,
        loadChildren: () => import("../views/media-tester/media-tester.module").then(m => m.MediaTesterModule)
    },
    {
        path: ROUTE_KIDS,
        loadChildren: () => import("../modules/kids-landing-app/kids-view.routing.module").then(m => m.KidsViewRoutingModule)
    },
    {
        path: ROUTE_CONVERSATIONS,
        loadChildren: () => import("../views/conversations-view/conversations-view.module").then(m => m.ConversationsViewModule),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard]
    },
    {
        path: ROUTE_MARKETING,
        loadChildren: () => import("../views/marketing-landing-view/marketing-landing-view.module").then(m => m.MarketingLandingViewModule)
    },
    {
        path: ROUTE_CORPORATE,
        loadChildren: () => import("../views/corporate-view/corporate-view.module").then(m => m.CorporateViewModule)
    },
    {
        path: ROUTE_VI_LANDING,
        loadChildren: () => import("../views/vietnam-landing-page-view/vietnam-landing-page-view.module").then(m => m.VietnamLandingPageViewModule)
    },
    {
        path: ROUTE_DAILY_ENGLISH_LANDING,
        loadChildren: () => import("../views/topic-based-landing-pages/daily-english-view/daily-english-view.module").then(m => m.DailyEnglishViewModule)
    },
    {
        path: ROUTE_ACADEMIC_ENGLISH_LANDING,
        loadChildren: () => import("../views/topic-based-landing-pages/academic-english-view/academic-english-view.module").then(m => m.AcademicEnglishViewModule)
    },
    {
        path: ROUTE_BUSINESS_ENGLISH_LANDING,
        loadChildren: () => import("../views/topic-based-landing-pages/business-english-view/business-english-view.module").then(m => m.BusinessEnglishViewModule)
    },
    {
        path: ROUTE_ENGLISH_FOR_KIDS_LANDING,
        loadChildren: () => import("../views/topic-based-landing-pages/english-for-kids-view/english-for-kids-view.module").then(m => m.EnglishForKidsViewModule)
    },
    {
        path: ROUTE_VI_LANDING_CAMPAIGN,
        loadComponent: () => import("../views/vietnam-landing-page-view/vi-campaign/vi-campaign.component").then(m => m.ViCampaignComponent)
    },
    {
        path: `${ROUTE_AGORA_MEETING}/:meetingId`,
        loadChildren: () => import("../../agora-client-app/agora-client-app.module").then(m => m.AgoraClientAppModule),
        canActivate: [WebviewGuard],
        canLoad: [WebviewGuard]
    },
    {
        path: ROUTE_VI_TELCO_SUBSCRIPTIONS,
        loadComponent: () => import("../views/vietnam-landing-page-view/vi-campaign-package/vi-campaign-package.component").then(m => m.ViCampaignPackageComponent)
    },
    {
        path: ROUTE_TESTCENTRAL,
        loadComponent: () => import("../../test-central/test-central.component").then(m => m.TestCentralComponent),
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        children: [
            {
                path: "tests",
                loadComponent: () => import("../../test-central/test-view/test-view.component").then(m => m.TestViewComponent)
            },
            {
                path: "test-prep",
                loadComponent: () => import("../../test-central/test-prep/test-prep.component").then(m => m.TestPrepComponent)
            },
            { path: "", redirectTo: "tests", pathMatch: "full" }
        ]
    },
    {
        path: ROUTE_BUSINESS_SKILLS_COURSES,
        loadChildren: () => import("../views/business-skills-course/business-skills-course.module").then(m => m.BusinessSkillsCourseModule)
    },
    {
        path: ROUTE_COOORI_UNIVERSITY,
        loadComponent: () => import("../views/cooori-toeic-page/cooori-toeic-page.component").then(m => m.CoooriToeicPageComponent)
    },
    {
        path: ROUTE_VERSANT_TESTS,
        children: [
            {
                path: "school",
                loadComponent: () => import("../views/versant-tests/versant-tests.component").then(m => m.VersantTestsComponent)
            },
            {
                path: "corporate",
                loadComponent: () => import("../views/versant-tests/versant-tests-corporate/versant-tests-corporate.component").then(m => m.VersantTestsCorporateComponent)
            },
            {
                path: "consumer",
                loadComponent: () => import("../views/versant-tests/versant-tests.component").then(m => m.VersantTestsComponent)
            },
            { path: "", redirectTo: "school", pathMatch: "full" }
        ]
    },
    {
        path: ROUTE_LTI_ACCOUNT_LINKING,
        loadComponent: () => import("../modules/lti-account-linking/lti-account-linking.component").then(m => m.LtiAccountLinkingComponent)
    },
    {
        path: ROUTE_TR_LANDING,
        loadComponent: () => import("../views/tr-landing-view/tr-landing-view.component").then(m => m.TrLandingViewComponent),
        children: [
            { path: "**", redirectTo: "", pathMatch: "full" }
        ]
    }
];
